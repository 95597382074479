import * as React from 'react'
import { withTheme } from 'emotion-theming'
import { css, Global } from '@emotion/core'

const HeaderStyles = withTheme(() => (
  <Global
    styles={css`
      img[alt='Logo'] {
        display: block;
        width: 155px;
        height: 30px;
      }
    `}
  />
))

export default HeaderStyles
