import * as React from 'react'
import { withTheme } from 'emotion-theming'
import { css, Global } from '@emotion/core'

const FormCss = withTheme(() => (
  <Global
    styles={css`
      .hubspot-form {
        max-width: 600px !important;
        margin: 0 auto;
      }
    `}
  />
))

export default FormCss
