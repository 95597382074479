import * as React from 'react'
import { withTheme } from 'emotion-theming'
import { css, Global } from '@emotion/core'

const ColumnsCss = withTheme(() => (
  <Global
    styles={css`
      .left-icon-mobile {
        .leshen-column-wrapper {
          @media (max-width: 939px) {
            flex-direction: row;
            margin-left: auto;
            margin-right: auto;
            max-width: 400px;
            justify-content: space-around;

            .content {
              width: 50%;
            }

            .gatsby-image-wrapper img {
              object-fit: contain;
            }
          }
        }

        &.left-content-mobile {
          .content {
            @media (max-width: 939px) {
              align-items: flex-start;
            }
          }
        }

        &.content-75 {
          @media (max-width: 939px) {
            .content {
              width: 75%;
            }

            .gatsby-image-wrapper {
              width: 25%;
            }
          }
        }
      }

      .pt-0-column-mobile {
        @media (max-width: 768px) {
          .leshen-column-wrapper {
            .content {
              padding-top: 0 !important;
            }
          }
        }
      }

      .card-icon {
        width: 65px;
      }

      .left-aligned-image {
        .gatsby-image-wrapper {
          margin-left: 0;
        }
      }

      .no-content-padding-left {
        .content {
          padding-left: 0 !important;
        }
      }

      .no-content-padding-mobile {
        @media screen and (max-width: 549px) {
          .content {
            padding: 0 !important;
          }
        }
      }
    `}
  />
))

export default ColumnsCss
