import * as React from 'react'
import { withTheme } from 'emotion-theming'
import { css, Global } from '@emotion/core'

const AccordionCss = withTheme(() => (
  <Global
    styles={css`
      .background-color-dark {
        .leshen-accordion {
          button[data-open='false'] {
            color: #ffffff !important;
          }
          button[data-open='true'] {
            color: #1990ff !important;
          }
        }
      }
    `}
  />
))

export default AccordionCss
