import * as React from 'react'
import { withTheme } from 'emotion-theming'
import { css, Global } from '@emotion/core'

const FooterStyles = withTheme(() => (
  <Global
    styles={css`
      img[alt='Logo'] {
        display: block;
        width: 155px;
        height: 30px;
      }

      .leshen-footer {
        a {
          color: black;
        }
      }
    `}
  />
))

export default FooterStyles
